<!--
 * @Description: 运营位管理--焦点图列表页面
 * @Author: 小广
 * @Date: 2019-06-17 18:16:21
 * @LastEditors: 小广
 * @LastEditTime: 2019-12-09 20:01:31
 -->
<template>
  <div class="FocusManageList-wrapper">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers"
          :isMultiSelect="true">
      <template #headerSlot>
        <v-button text="新增"
                  permission="add"
                  @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="创建时间段"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"></v-datepicker>
        <v-input label="标题"
                 v-model="searchParams.title"></v-input>
        <v-select label="跳转类型"
                  v-model="searchParams.focusType"
                  :options="focusTypeOps"></v-select>
        <v-select label="发布位置"
                  v-model="searchParams.type"
                  :options="homeTypeOps"></v-select>
        <v-select label="状态"
                  v-model="searchParams.status"
                  :options="statusOps"></v-select>
        <v-select2 label="发布项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司"
                   v-model="searchParams.regionId"
                   v-bind="companyParams"
                   :width="160"></v-select2>
        <v-input label="创建人"
                 v-model="searchParams.createUser"></v-input>
      </template>
      <template #operateSlot="scope">
        <div v-if="scope.row.status !== 0">
          <v-button text="编辑"
                    type="text"
                    permission="update"
                    @click="edit(scope.row)"></v-button>
          <v-button text="关闭"
                    type="text"
                    permission="delete"
                    @click="offHandle(scope.row)"></v-button>
        </div>
        <v-button v-else
                  text="查看"
                  type="text"
                  permission="update"
                  @click="edit(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button v-if="auditPermission"
                  text="批量审核"
                  @click="batchAuditOffHandle(scope.selectedData, 2)"></v-button>
        <v-button text="批量关闭"
                  permission="delete"
                  @click="batchAuditOffHandle(scope.selectedData, 1)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getRegionsListURL, batchURL } from './api'
import { statusMap, statusOps, focusTypeMap, focusTypeOps,homeTypeMap,homeTypeOps, publishTypeOps } from './map'
import moment from 'moment'
import { communityParams } from 'common/select2Params'

export default {
  name: 'FocusManageList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps(1),
      focusTypeOps: focusTypeOps(1),
      homeTypeOps: homeTypeOps(1),
      publishTypeOps: publishTypeOps(1),
      communityParams,
      companyParams: {
        searchUrl: getRegionsListURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        },
        method: 'get',
        response: {
          text: 'name'
        }
      },
      searchParams: {
        startTime: moment().subtract(30, 'd').format('YYYY-MM-DD HH:mm'),
        endTime: moment().format('YYYY-MM-DD HH:mm'),
        title: '',
        createUser: '',
        communityId: '',
        regionId: '',
        focusType: undefined,
        type: undefined,
        status: undefined
      },
      headers: [
        {
          prop: 'title',
          label: '标题'
        },
        {
          prop: 'focusTypeText',
          label: '跳转类型',
          formatter (row) {
            return focusTypeMap[row.focusType]
          }
        },
        {
          prop: 'orgName',
          label: '发布位置',
          formatter (row) {
            return homeTypeMap[row.type]
          }
        },
        {
          prop: 'communityName',
          label: '发布项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'onlineFromDate',
          label: '上线时间'
        },
        {
          prop: 'onlineToDate',
          label: '下线时间'
        },
        {
          prop: 'sort',
          label: '排序',
          sort: '1',
          align: 'right'
        },
        {
          prop: 'statusStr',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'readnum',
          label: '阅读数',
          align: 'right'
        },
        {
          prop: 'username',
          label: '创建人'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ]
    }
  },

  computed: {
    auditPermission () {
      let audit = this.$store.state.permission.audit
      let check = this.$store.state.permission.check
      return !!audit || !!check
    }
  },
  methods: {

    // 查看
    create () {
      this.$router.push({
        name: 'focusManageForm'
      })
    },

    // 编辑
    edit (row) {
      this.$router.push({
        name: 'focusManageForm',
        query: {
          id: row.id
        }
      })
    },

    // 关闭的操作
    async offHandle (row) {
      let isOk = await this.$confirm('确认关闭吗？', { title: '提示' })
      isOk && this.requestForOff(row)
    },

    // 批量审核/关闭
    async batchAuditOffHandle (selectedData, status) {
      let list = selectedData.data || []
      if (list.length) {
        let firstStatus = list[0].status
        // 取出所选list，判断里面的数据状态是否一致
        let allSame = true
        list.forEach(item => {
          if (firstStatus !== item.status) {
            allSame = false
          }
        })

        if (allSame) {
          /// 代表所选数据状态一致，进一步判断
          if (status === 1) {
            // 关闭的处理
            if (firstStatus === 0) {
              this.$message({ type: 'error', message: '已经关闭，请勿重复操作', center: true })
            } else {
              let isOk = await this.$confirm('确定执行该操作？', { title: '提示' })
              isOk && this.requestForAuditOff(selectedData, status)
            }
          } else if (status === 2) {
            // 审核
            console.log(firstStatus)
            if (firstStatus === 1) {
              this.$message({ type: 'error', message: '焦点图已经通过审核，请勿重复操作', center: true })
            } else if (firstStatus !== 2) {
              this.$message({ type: 'error', message: '操作失败，请确认状态信息', center: true })
            } else {
              let isOk = await this.$confirm('确定执行该操作？', { title: '提示' })
              isOk && this.requestForAuditOff(selectedData, status)
            }
          }
        } else {
          this.$message({ type: 'error', message: '请确认焦点图在同一状态', center: true })
        }
      } else {
        this.$message({ type: 'error', message: '请先选择要操作的数据', center: true })
      }
    },

    // 关闭的请求
    requestForOff (row) {
      let _this = this
      this.$axios({
        method: 'PUT',
        url: `${batchURL}/${row.id}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: this.$qs.stringify({
          status: row.status
        })
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    // 批量审核/关闭的请求
    requestForAuditOff (selectedData, status) {
      let _this = this
      this.$axios({
        method: 'PUT',
        url: `${batchURL}?status=${status}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: this.$qs.stringify({
          ids: selectedData.ids.join(',')
        })
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    }
  }
}
</script>
