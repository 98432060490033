// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}focusInfo/info/all`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}focus/estate/export`
// 获取分公司列表
const getRegionsListURL = `${API_CONFIG.controlBaseURL}region/select2`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}focusInfo/status`

// 新增 post
const createURL = `${API_CONFIG.butlerBaseURL}focusInfo/info`
// 编辑 PUT
const editURL = `${API_CONFIG.butlerBaseURL}focus/info`
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}focus/info`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=focus`

// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 检测园区是否可以跳转
const checkSkipCommunityURL = `${API_CONFIG.shopBaseURL}skipLifeShu/checkSkipCommunity`

export {
  getListURL,
  exportListURL,
  getRegionsListURL,
  batchURL,
  createURL,
  editURL,
  queryURL,
  uploadURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  checkSkipCommunityURL
}
