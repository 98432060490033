import {
  mapHelper
} from 'common/utils'

// 状态
const status = [{
  text: '已关闭',
  value: 0
},
{
  text: '已审核',
  value: 1
},
{
  text: '待审核',
  value: 2
},
{
  text: '已退回',
  value: 3
}
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)


// 跳转类型
const focusType = [{
  text: '至详情页',
  value: 1
},
{
  text: '至功能页',
  value: 2
},
{
  text: '至外部链接',
  value: 3
}
]
const {
  map: focusTypeMap,
  setOps: focusTypeOps
} = mapHelper.setMap(focusType)

const { // 防止数据污染
  map: focusTypeLableMap,
  setOps: focusTypeLableOps
} = mapHelper.setMap(focusType,'label')

const focusotherType = [{
  text: '至详情页',
  value: 1
},
{
  text: '至功能页',
  disabled: true,
  value: 2
},
{
  text: '至外部链接',
  value: 3
}
]
const {
  map: focusotherTypeMap,
  setOps: focusotherTypeOps
} = mapHelper.setMap(focusotherType,'label')

const focusotherTypeMapHelper = [
  {
    text:  focusTypeLableOps,
    value: 1
  },
  {
    text:  focusotherTypeOps,
    value: 15
  },
]
const {
  map: focusotherTypeMapHelperMap,
  setOps: focusotherTypeMapHelperOps
} = mapHelper.setMap(focusotherTypeMapHelper)


// 发布位置
const publishType = [{
  text: '首页焦点图',
  value: 1
}]
const {
  map: publishTypeMap,
  setOps: publishTypeOps
} = mapHelper.setMap(publishType)
const { // 防止数据污染
  map: publishTypeLabelMap,
  setOps: publishTypeLabelOps
} = mapHelper.setMap(publishType, 'label')

const homeType = [
  {
    text: '首页焦点图',
    value: 1
  },
  {
    text: '家头条焦点图',
    value: 15
  },
]
const {
  map: homeTypeMap,
  setOps: homeTypeOps
} = mapHelper.setMap(homeType)
const {// 防止数据污染
  map: homeTypeLabelMap,
  setOps: homeTypeLabelOps
} = mapHelper.setMap(homeType, 'label')

const publishTypeMapHelper = [
  {
    text:  homeTypeLabelOps,
    value: 1
  },
  {
    text:  publishTypeLabelOps,
    value: 2
  },
  {
    text:  homeTypeLabelOps,
    value: 3
  }
]
const {
  map: publishTypeMapHelperMap,
  setOps: publishTypeMapHelperOps
} = mapHelper.setMap(publishTypeMapHelper)


// 详情页面的审核状态
const auditStatus = [{
  text: '待审核',
  value: 2
},
{
  text: '已审核',
  value: 1
},
{
  text: '退回',
  value: 3
}
]

const {
  map: auditStatusMap,
  setOps: auditStatusOps
} = mapHelper.setMap(auditStatus)

// searchRegionType
const searchRegionType = [
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]

const {
  map: searchRegionTypeMap,
  setOps: searchRegionTypeOps
} = mapHelper.setMap(searchRegionType)

const communityStatus = [{
  text: '正常',
  value: 0
}, {
  text: '关闭',
  value: 1
}]
const {
  map: communityStatusMap,
  setOps: communityStatusOps
} = mapHelper.setMap(communityStatus)

// jump-go校验参数映射
const jumpGoCodeMap = {
  storeCode: 'storeIdList', // 门店
  productCode: 'productIdList', // 商品
  secKillCode: 'secKillIdList', // 秒杀
  discountTopicCode: 'discountTopicId', // 促销专题
  knowledgeInfoCode: 'knowledgeInfoIdList', // 资讯
  postsCode: 'tableIdList', // 帖子
  groupCode: 'groupIdList', // 社群
  socialActivityCode: 'socialActivityIdList', // 社区活动
  surveyCode: 'questionIdList', // 问卷调查
  selfRuleCode: 'selfRuleIdList', // 自治规约
  autonomyVoteCode: 'questionNaireIdList', // 自治表决
  workRoomCode: 'workRoomIdList' // 社区工作室
}

export {
  statusMap,
  statusOps,
  focusTypeMap,
  focusTypeOps,
  publishTypeMap,
  homeTypeLabelMap,
  homeTypeMap,
  homeTypeOps,
  publishTypeOps,
  auditStatusMap,
  auditStatusOps,
  searchRegionTypeMap,
  searchRegionTypeOps,
  communityStatusMap,
  communityStatusOps,
  jumpGoCodeMap,
  publishTypeMapHelperMap,
  focusotherTypeMapHelperMap,
}
